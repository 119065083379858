import * as React from 'react';

import { APPLE_STORE, BUSINESS_LOGIN, GOOGLE_PLAY, S3_IMAGES } from '../utils/constants';

import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const StartHere = (): JSX.Element => {
  const { i18n } = useTranslation();

  return (
    <div className="bg-blue-300 md:h-screen">
      <div>
        <div className="text-center">
          <StaticImage className="w-72 m-auto " src={'../images/chamba.png'} alt="Workflow" />
        </div>
        <div className="flex flex-col md:flex-row w-full justify-center items-center md:items-stretch">
          <div className="bg-blue-200 rounded-lg text-primary p-10 m-5 w-4/5 md:w-2/6">
            <h2 className="font-bold text-3xl text-center">Pros</h2>
            <div className="flex w-full justify-center my-10">
              <img src={`${S3_IMAGES}/sign-in-pros.svg`} alt="sign-in-pros" />
            </div>
            <div className="my-10">
              <p className="text-center">
                Download the app to see all the shifts available in your city. Welcome to our
                community!
              </p>
            </div>
            <div className="flex justify-center flex-col items-center">
              <OutboundLink href={GOOGLE_PLAY} className="text-center">
                <StaticImage
                  alt="Play store logo"
                  className="sm:w-48 m-auto w-1/2 "
                  src={'../images/GooglePlayStore.svg'}
                />
              </OutboundLink>
              <OutboundLink className="text-center" href={APPLE_STORE}>
                <StaticImage
                  alt="Apple Store logo"
                  className="sm:w-48 w-1/2 "
                  src={'../images/AppStore.svg'}
                />
              </OutboundLink>
            </div>
          </div>
          <div className="bg-blue-200 rounded-lg text-primary p-10 m-5 w-4/5 md:w-2/6">
            <h2 className="font-bold text-3xl text-center">Business</h2>
            <div className="flex w-full justify-center my-10">
              <img src={`${S3_IMAGES}/sign-in-business.svg`} alt="sign-in-pros" />
            </div>
            <div className="my-10">
              <p className="text-center">
                Book Pros, manage your shifts, view the time sheets and check the invoices. All in
                Chamba business dashboard!
              </p>
            </div>
            <div className="flex justify-center flex-col items-center">
              <OutboundLink
                href={`${BUSINESS_LOGIN}?lang=${i18n.language}`}
                className="bg-primary text-white rounded-full px-10 text-center p-2"
              >
                Business Login
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartHere;
